(function () {
	'use strict';

	angular
		.module('event')
		.factory('quickEdit', quickEdit)
		.factory('interventions', ['seedcodeCalendar', interventions])
		.factory('eventSettings', [
			'$timeout',
			'$location',
			'utilities',
			'calendarIO',
			'seedcodeCalendar',
			eventSettings,
		]);

	function quickEdit() {
		return {};
	}

	function interventions(seedcodeCalendar) {
		return {
			init: init,
		};

		function init(id, start, end, callback) {
			overlapsDayback(id, start, end, interventionResult);
			timedEventPad(id, start, end, interventionResult);
			clearDays(id, start, end, interventionResult);

			function interventionResult(result) {
				if (callback) {
					callback(result);
				}
			}
		}

		function interventionEvents(
			start,
			end,
			rangeStart,
			rangeEnd,
			callback
		) {
			var events;
			var viewEvents;

			var view = seedcodeCalendar.get('element').fullCalendar('getView');
			var viewRangeStart = view.start;
			var viewRangeEnd = view.end;

			if (
				(start.isBefore(viewRangeEnd, 'day') ||
					start.isSame(viewRangeEnd, 'day')) &&
				(end.isAfter(viewRangeStart, 'day') ||
					end.isSame(viewRangeStart, 'day'))
			) {
				viewEvents = seedcodeCalendar
					.get('element')
					.fullCalendar('clientEvents');
				eventsReceived(viewEvents);
			} else {
				//Get events in the background
				seedcodeCalendar
					.get('element')
					.fullCalendar(
						'getEvents',
						moment(start.format('YYYY-MM-DD')),
						moment(end.format('YYYY-MM-DD')),
						function (events) {
							eventsReceived(events);
						}
					);
			}

			//After all events are received
			function eventsReceived(rangeEvents) {
				var events = getEventsInRange(
					rangeEvents,
					rangeStart,
					rangeEnd
				);
				callback(events);
			}
		}

		function getEventsInRange(events, start, end) {
			start = $.fullCalendar.moment(start).stripZone();
			end = $.fullCalendar.moment(end).stripZone();

			var result = [];
			for (var i = 0; i < events.length; i++) {
				var eventStart = events[i].start.clone().stripZone();
				var eventEnd = events[i].end.clone().stripZone();

				if (
					(eventStart.isBefore(end, 'minute') ||
						eventStart.isSame(end, 'minute')) &&
					(eventEnd.isAfter(start, 'minute') ||
						eventEnd.isSame(start, 'minute'))
				) {
					result.push(events[i]);
				}
			}
			return result;
		}

		//Interventions
		//Overlaps DayBack
		function overlapsDayback(id, start, end, callback) {
			var rangeStart = moment(start).add(1, 'minute');
			var rangeEnd = moment(end).subtract(1, 'minute');

			interventionEvents(
				start,
				end,
				rangeStart,
				rangeEnd,
				createIntervention
			);

			function createIntervention(events) {
				//Process returned events for intervention
				for (var i = 0; i < events.length; i++) {
					if (events[i].isDayback) {
						if (callback) {
							var interventionData = {
								id: 'dayback',
								message:
									'This overlaps time that you’re defending',
							};
							callback(interventionData);
						}
						break;
					}
				}
			}
		}

		//Timed event pad
		function timedEventPad(id, start, end, callback) {
			var padMinutes = 30;
			var rangeStart = start.clone().subtract(padMinutes, 'minutes');
			var rangeEnd = end.clone().add(padMinutes, 'minutes');
			interventionEvents(
				start,
				end,
				rangeStart,
				rangeEnd,
				createIntervention
			);

			function createIntervention(events) {
				//Process returned events for intervention
				for (var i = 0; i < events.length; i++) {
					if (
						!events[i].allDay &&
						events[i].id !== id &&
						!events[i].isDayback
					) {
						if (callback) {
							var interventionData = {
								id: 'pad',
								message:
									'This is too close to another timed event',
							};
							callback(interventionData);
						}
						break;
					}
				}
			}
		}

		//Clear days
		function clearDays(id, start, end, callback) {
			//For calendar week
			if (!start.isSame(end, 'day')) {
				return;
			}
			var rangeStart = $.fullCalendar
				.moment(start)
				.stripTime()
				.startOf('week');
			var rangeEnd = rangeStart.clone().add(1, 'weeks');

			interventionEvents(
				start,
				end,
				rangeStart,
				rangeEnd,
				createIntervention
			);

			function createIntervention(events) {
				var eventDay;
				var targetDay = start.day();
				var dayMatrix = {
					0: 0,
					1: 0,
					2: 0,
					3: 0,
					4: 0,
					5: 0,
					6: 0,
				};

				for (var i = 0; i < events.length; i++) {
					if (events[i].id !== id && !events[i].isDayback) {
						eventDay = events[i].start.day();
						dayMatrix[eventDay]++;
					}
				}

				for (var property in dayMatrix) {
					if (Number(property) === targetDay) {
						if (dayMatrix[property]) {
							return;
						}
					} else if (!dayMatrix[property]) {
						return;
					}
				}

				if (callback) {
					var interventionData = {
						id: 'clear',
						message: 'This is the only clear day for the week',
					};
					callback(interventionData);
				}
			}
		}
	}

	function eventSettings(
		$timeout,
		$location,
		utilities,
		calendarIO,
		seedcodeCalendar
	) {
		return {
			addField: addField,
			addButton: addButton,
		};

		function addField(editEvent) {
			//save the event like we do with a Custom action and head to settings on the callback
			//Test if our event has been modified and needs to be saved. Returns the editEvent object
			var calendar = editEvent.schedule;
			var modifiedEvent = !editEvent
				? false
				: calendarIO.eventChanged(editEvent, editEvent.event);
			if (modifiedEvent) {
				var options = {};
				options.showConfirmation = calendarIO.saveRequiresConfirmation(
					editEvent.event
				);
				options.isCustomAction = true;
				//Normalize end date in edit object for all day events
				options.endShifted = false;
				//If our event has been modified we need to save it
				calendarIO.getEventChanges(
					editEvent.event,
					editEvent,
					null,
					eventModifiedCallback,
					options
				);
			} else {
				eventModifiedCallback(editEvent.event, true);
			}

			function eventModifiedCallback(event, notAsync) {
				if (event) {
					seedcodeCalendar.init('activeEvent', {
						event: event,
					});
					var path =
						'/settings/source/' +
						calendar.sourceTypeID +
						'/' +
						utilities.stringToID(calendar.id) +
						'/';
					if (!notAsync) {
						$timeout(function () {
							$location
								.path(path)
								.search('action', 'create')
								.search('entity', 'customField');
						}, 0);
					} else {
						$location
							.path(path)
							.search('action', 'create')
							.search('entity', 'customField');
					}
				}
			}
		}

		function addButton(editEvent) {
			//save the event like we do with a Custom action and head to settings on the callback
			//Test if our event has been modified and needs to be saved. Returns the editEvent object
			var calendar = editEvent.schedule;
			var modifiedEvent = !editEvent
				? false
				: calendarIO.eventChanged(editEvent, editEvent.event);
			if (modifiedEvent) {
				var options = {};
				options.showConfirmation = calendarIO.saveRequiresConfirmation(
					editEvent.event
				);
				options.isCustomAction = true;
				//Normalize end date in edit object for all day events
				options.endShifted = false;
				//If our event has been modified we need to save it
				calendarIO.getEventChanges(
					editEvent.event,
					editEvent,
					null,
					eventModifiedCallback,
					options
				);
			} else {
				eventModifiedCallback(editEvent.event, true);
			}

			function eventModifiedCallback(event, notAsync) {
				if (event) {
					seedcodeCalendar.init('activeEvent', {
						event: event,
					});
					var path =
						'/settings/source/' +
						calendar.sourceTypeID +
						'/' +
						utilities.stringToID(calendar.id) +
						'/';
					if (!notAsync) {
						$timeout(function () {
							$location
								.path(path)
								.search('action', 'create')
								.search('entity', 'customButton');
						}, 0);
					} else {
						$location
							.path(path)
							.search('action', 'create')
							.search('entity', 'customButton');
					}
				}
			}
		}
	}
})();
